import { EventEmitter, Injectable, Output } from '@angular/core';
import { DataOptions } from '@xpo-ltl/data-api';
import {
  AcAutomationApiService,
  BulkUpdateAccessorialLiteralsResp,
  BulkUpdateAccessorialLiteralsRqst,
  CreateAccessorialLiteralCorrectionResp,
  CreateAccessorialLiteralCorrectionRqst,
  CreateAccessorialLiteralResp,
  CreateAccessorialLiteralRqst,
  GetAccessorialLiteralRateResp,
  GetAccessorialLiteralRateRqst,
  ListAccessorialLiteralDistrictsResp,
  ListAccessorialLiteralHistsResp,
  ListAccessorialLiteralHistsRqst,
  ListAccessorialLiteralRegionsResp,
  ListAccessorialLiteralsResp,
  ListAccessorialLiteralsRqst,
  ListDetentionDetailsResp,
  ListDetentionDetailsRqst,
  ListShipmentAccessorialLiteralsQuery,
  ListShipmentAccessorialLiteralsResp,
} from '@xpo-ltl/sdk-acautomation';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AcAutomationService {
  sicZonesChange$ = new Subject<boolean>();
  resetSicZones$ = new Subject<void>();

  constructor(private acAutomationApiService: AcAutomationApiService) {}

  listAccessorialLiterals(
    request: ListAccessorialLiteralsRqst,
    dataOptions?: DataOptions
  ): Observable<ListAccessorialLiteralsResp> {
    return this.acAutomationApiService.listAccessorialLiterals(request, dataOptions);
  }

  bulkUpdateAccessorialLiterals(
    request: BulkUpdateAccessorialLiteralsRqst
  ): Observable<BulkUpdateAccessorialLiteralsResp> {
    return this.acAutomationApiService.bulkUpdateAccessorialLiterals(request);
  }

  getAccessorialLiteralRate(request: GetAccessorialLiteralRateRqst): Observable<GetAccessorialLiteralRateResp> {
    return this.acAutomationApiService.getAccessorialLiteralRate(request);
  }

  createAccessorialLiteralCorrection(
    request: CreateAccessorialLiteralCorrectionRqst
  ): Observable<CreateAccessorialLiteralCorrectionResp> {
    return this.acAutomationApiService.createAccessorialLiteralCorrection(request);
  }

  listShipmentAccessorialLiterals(
    queryParams: ListShipmentAccessorialLiteralsQuery
  ): Observable<ListShipmentAccessorialLiteralsResp> {
    return this.acAutomationApiService.listShipmentAccessorialLiterals(queryParams);
  }

  createAccessorialLiteral(request: CreateAccessorialLiteralRqst): Observable<CreateAccessorialLiteralResp> {
    return this.acAutomationApiService.createAccessorialLiteral(request);
  }

  listAccessorialLiteralHists(request: ListAccessorialLiteralHistsRqst): Observable<ListAccessorialLiteralHistsResp> {
    return this.acAutomationApiService.listAccessorialLiteralHists(request);
  }

  listAccessorialLiteralDistricts(dataOptions?: DataOptions): Observable<ListAccessorialLiteralDistrictsResp> {
    return this.acAutomationApiService.listAccessorialLiteralDistricts(dataOptions);
  }

  listAccessorialLiteralRegions(dataOptions?: DataOptions): Observable<ListAccessorialLiteralRegionsResp> {
    return this.acAutomationApiService.listAccessorialLiteralRegions(dataOptions);
  }

  listDetentionDetails(request: ListDetentionDetailsRqst): Observable<ListDetentionDetailsResp> {
    return this.acAutomationApiService.listDetentionDetails(request);
  }

  emitSicValueChange(isDisabled: boolean): void {
    this.sicZonesChange$.next(isDisabled);
  }

  resetSicZonesSelector(): void {
    this.resetSicZones$.next();
  }
}
